<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-map-detail"
      :title="modalObject.item.name"
      hide-footer
      scrollable
      cancel-variant="outline-secondary"
      v-if="modalObject.item"
      @hidden="hiddenModal"
      size="lg"
      :content-class="className"
    >
      <b-row v-if="geoMapDetail !== null">
        <b-col cols="12" xl="6" md="6"> 
          <div class="image-container">
            <b-img
              class="mx-auto detail-image"
              :src="geoMapDetail.pictureUrl"
            />
          </div>
        </b-col>
        <b-col cols="12" xl="6" md="6"> 
          <div class="breakdown-container">
            <div class="title-header">
              <h4>{{ $t('Breakdown') }}</h4>
            </div>
            <hr />

            <div class="breakdown-body">
              <div class="breakdown-item" v-for="regionItem in regionPureResults" :key="regionItem.resultsTable">
                <div class="pop-text">{{ regionItem.resultSubTitle.replaceAll('*', ' ') }} :</div>
                <div class="value-text">
                  <span>%{{ regionItem.currentResult.toFixed(1) }}</span>
                  <vue-apex-charts
                    type="radialBar"
                    height="40"
                    width="40"
                    :options="onGetChartOption(modalObject.item.color)"
                    :series="[regionItem.currentResult.toFixed(1)]"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="geoMapDetail !== null">
        <b-col cols="12" xl="12" md="12" class="mt-2">
          <div class="title-header">
            <h4>{{ $t('Description') }}</h4>
          </div>
          <hr />
          <div class="description-container">
            <p v-html="formattedDescription"></p>
          </div>
        </b-col>
        <!-- <b-col cols="12" xl="6" md="6" class="mt-2">          
          <div class="title-header">
            <h4>{{ $t('Supplementary Info') }}</h4>
          </div>
          <hr />
          <div class="description-container">
            <p v-html="geoMapDetail.supplementaryInfo"></p>
          </div>
        </b-col> -->
        <b-col cols="12" xl="12" md="12" class="mt-2">
          <div class="title-header">
            <h4>{{ $t('Referance Samples') }}</h4>
          </div>
          <hr />
          <div class="description-container">
            <p v-html="geoMapDetail.referenceSamples"></p>
          </div>
        </b-col>
        <b-col cols="12" xl="12" md="12" class="mt-2">
          <div class="title-header">
            <h4>{{ $t('Research Link(s)') }}</h4>
          </div>
          <hr />
          <div class="description-container">
            <p v-html="geoMapDetail.researchLinks"></p>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { G25_RESULT_TABS } from '@/common/config'
import AlertService from '@/common/alert.service'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    className: {
      type: String,
      default: 'geo-map-periodical',
    },
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      geoMapDetail: null,
    }
  },
  methods: {
    initialModal() {
      this.$store.dispatch('operation/fetchMapDetailDatas', { id: this.modalObject.item.id })
        .then(response => {
          if (response.statusCode === 200) {
            this.geoMapDetail = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        });
      this.$refs['modal-map-detail'].show();
    },
    hiddenModal() {
      this.$refs['modal-map-detail'].hide()
      this.modalObject.showModal = false;
    },
    onGetChartOption(color) {
      /* eslint-disable */
      const barColor = color ? color : '#00ADB5';
      return {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: [barColor],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: this.ddAlpha(color, 0.1),
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      };
    },
    ddAlpha(color, opacity) {
      let _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
  },
  computed: {
    regionPureResults() {
      let results = null;

      if (this.$router.currentRoute.params.type === G25_RESULT_TABS.PERIODICAL.code) {
        results = this.$store.getters['ancestrybreakdown/getRegionPureResults'];
      } else if (this.$router.currentRoute.params.type === G25_RESULT_TABS.ANCIENT_DIY_MODEL.code) {
        results = this.$store.getters['customancestrybreakdown/getCustomAncBrPureResult'];
      } else {
        results = this.$store.getters['periodicalancestrybreakdown/getPeriodicalAncBrPureResult'];
      }
     
      if (results.outPuts) {
        let filterResults = results.outPuts.filter(x => x.resultsTable.includes(this.modalObject.item.sourceCode));
        filterResults = filterResults.map(item => {
          if (item.resultsTable.includes(':')) {
            /* eslint-disable */
            item.resultSubTitle = item.resultsTable.split(':')[1];
          } else {
            item.resultSubTitle = item.resultsTable;
          }
          return item;
        });
       
        const newfilterResults = JSON.parse(JSON.stringify(filterResults)).reduce((list, item) => {
          let findItem = list.find(x => x.resultSubTitle === item.resultSubTitle);
          if (findItem) {
            findItem.currentResult += item.currentResult;
          } else {
            list.push(item);
          }
          return list;
        }, []);
        
        return newfilterResults;
      }
      return [];
    },

    formattedDescription() {
      return this.geoMapDetail.description ? this.geoMapDetail.description.replace(/\n/g, '<br>') : '';
    }
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>

.image-container {
  display: block;
  padding: 1rem 0.5rem;
  border-radius: 10px;
  width: 100% !important;

  img {
    height: 240px !important;
    width: 240px !important;
    border-radius: 10px;
  }
}

.breakdown-container {
  margin-top: 10px;

  .breakdown-body {
    .breakdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;

      .pop-text {
        font-size: 0.9rem;
        font-weight: bold;
        color:var(--lab-text-color-title-02);
      }

      .value-text {
        display: flex;
        align-items: center;
      
        span {
          font-size: 0.9rem;
          font-weight: bold;
          color:var(--lab-text-color-title-02);
          margin-right: 10px;
        }
      }
    }
  }
}

</style>

<style lang="scss">
// .geo-map-periodical {
//   .title-header h4 {
//     color: var(--lab-g25-supervised-color) !important;
//   }
// }
.dark-layout .geo-map-periodical {
  .modal-header h5 {
    color: var(--lab-g25-supervised-color) !important;
  }
  .title-header h4 {
    color: var(--lab-g25-supervised-color) !important;
  }
}

.geo-map-diy {
  .modal-header .close {
    background-color: #ffb1294f !important;
    color: var(--lab-g25-diy-color) !important;
  }
  .modal-header::before {
    background-color: var(--lab-g25-diy-color) !important;
  }
  .title-header::before {
    background-color: var(--lab-g25-diy-color) !important;
  }
}
.dark-layout .geo-map-diy {
  .modal-header h5 {
    color: var(--lab-g25-diy-color) !important;
  }
  .title-header h4 {
    color: var(--lab-g25-diy-color) !important;
  }
}

</style>
