<template>
  <div>
    <b-row class="hidden-mobile">
      <b-col cols="12" xl="8" md="8" class="mt-2">
        <periodical-periods :runAncestryBreakDown="runAncestryBreakDown"/>
      </b-col>
      <b-col cols="12" xl="4" md="4" class="mt-2">
        <periodical-help />
      </b-col>
    </b-row>

    <periodical-mobile-header class="hidden-desktop"  
        :runAncestryBreakDown="runAncestryBreakDown"
        :selectedRegionName="selectedRegionName" :mapRegions="mapRegions"/>

    <b-row>
      <b-col cols="12" xl="7" md="7"  class="periodical-card">
        <div class="map-card">
          <div class="map-header">
            <div class="map-header-fit-region">
              <div class="map-header-fit" v-if="regionResults">
                  <span><b>Fit: </b></span>
                  <span :style="{ color: CalculateModernPopColor(regionResults.result) }"> {{ regionResults.result }} ({{AncestryFitStatus(regionResults.result)}})</span>
              </div>
              <div class="vertical-hr" v-if="$store.state.appConfig.layout.windowWidth >= 1225"></div>
              <periodical-regions 
                v-if="$store.state.appConfig.layout.windowWidth >= 1225"
                :runAncestryBreakDown="runAncestryBreakDown" class="hidden-mobile"
                :selectedRegionName="selectedRegionName" :mapRegions="mapRegions"/>
            </div>
            <div class="map-header-config">
              <label class="lab-switch" v-if="$store.state.appConfig.layout.windowWidth >= 769">
                <input type="checkbox" v-model="$store.state.ancestrybreakdown.isViewAllRegionMap"  @change="onChangeShowRegionMap">
                <span class="slider round"></span>
              </label>

              <div class="setting-container" @click="openConfigDataModal"                   
                  v-show="selectedRegionName !== 'Select Region'"
                  v-if="this.$store.getters['ancestrybreakdown/getOrderSelectedRegion'] !== 1">
                <img :src="'https://api.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/settings.svg'">
              </div>
              <periodical-custom-pops :runAncestryBreakDown="runAncestryBreakDown"
                v-if="this.$store.getters['ancestrybreakdown/getOrderSelectedRegion'] === 1"/>
            </div>
          </div>
          <div class="d-flex">
            <periodical-regions 
              v-if="$store.state.appConfig.layout.windowWidth < 1225"
              :runAncestryBreakDown="runAncestryBreakDown" class="hidden-mobile"
              :selectedRegionName="selectedRegionName" :mapRegions="mapRegions"/>
          </div>
          
          <div v-if="$store.state.appConfig.layout.windowWidth >= 769">
            <hr class="mt-1"/>
            <div class="map-body">
              <ancient-break-down-map :geoList="ancestryGeoJson" v-if="isNormalMap">
              </ancient-break-down-map>
              <ancient-break-down-round-map v-if="!isNormalMap">
              </ancient-break-down-round-map>

              <div class="switch-map-type">
                <label class="lab-switch">
                  <input type="checkbox" v-model="isNormalMap">
                  <span class="slider round"></span>
                </label>
                <div class="switch-map-type-label">
                  <span v-show="!isNormalMap">3D</span>
                  <span v-show="isNormalMap" class="">2D</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" xl="5" md="5"  class="periodical-card">
        <periodical-pop-view></periodical-pop-view>
      </b-col>
    </b-row>
    <AncestryBreakDownConfigModal v-if="modalConfigDataObject.showModal" :modalObject="modalConfigDataObject"/>
  </div>
</template>

<script>
import ApiService from '@/common/api.service'
import { CalculateModernPopColor } from '@/common/custom-service/dna-source/utils'

import PeriodicalPeriods from './PeriodicalPeriods.vue'
import PeriodicalRegions from './PeriodicalRegions.vue'
import PeriodicalPopView from './PeriodicalPopView.vue'
import PeriodicalCustomPops from './PeriodicalCustomPops.vue'
import PeriodicalMobileHeader from './PeriodicalMobileHeader.vue'
import PeriodicalHelp from '../PeriodicalHelp.vue'

import AncientBreakDownMap from '../../../maps/AncientBreakDownMap.vue'
import AncientBreakDownRoundMap from '../../../maps/AncientBreakDownRoundMap.vue'

import AncestryBreakDownConfigModal from '../../../pages/AncestryBreakDownConfigModal.vue'

export default {
  components: {
    PeriodicalPeriods,
    PeriodicalRegions,
    PeriodicalPopView,
    PeriodicalHelp,
    PeriodicalCustomPops,
    AncientBreakDownMap,
    AncientBreakDownRoundMap,
    PeriodicalMobileHeader,
    AncestryBreakDownConfigModal,
  },
  directives: {
  },
  data() {
    return {
      IllusDnaService: this.$store.state.orderResult.IllusDnaService,
      /* eslint-disable */
      isNormalMap: true,
      modalConfigDataObject: {
        item: null,
        showModal: false,
        onSuccess: this.onRunCustomConfig,
      },
    }
  },
  methods: {
    /* eslint-disable */
    onRunCustomConfig() {
      this.$store.commit('ancestrybreakdown/setSelectedRegion', this.$store.getters['ancestrybreakdown/getOrderSelectedRegion']);
      this.$store.commit('operation/setAncestryGeoJson', []);
      this.runAncestryBreakDown(this.$store.getters['ancestrybreakdown/getOrderSelectedRegion'], 1);
    },
    runAncestryBreakDown(regionId, runType) {
      if(regionId === undefined) {
        regionId = this.$store.getters['ancestrybreakdown/getOrderSelectedRegion'];
      }
      const regions = this.$store.getters['operation/getRegions'];

      const findRegion = regions.find(x => x.id === regionId);
      if (!findRegion) return;
      
      const query = {
        presetRegionId: findRegion.id, 
        popNumber: findRegion.id === 1 ? this.$store.state.ancestrybreakdown.numberOfGlobalnPop : 0,
        selectedResultId: this.$store.state.orderResult.selectedResultId,
        selectedPeriodId: this.$store.state.operation.selectedAncientPeriod.id,
      };
      if (runType === undefined) {
        this.$store.dispatch('ancestrybreakdown/fetchRegionSourceDatas', query)
          .then(response => {
            if (response.statusCode === 200) {
              this.$store.commit('ancestrybreakdown/setRegionSourceDatas', response.result);
              const resolveSource = this.IllusDnaService.codeDataToArray(response.result.source);
              if (resolveSource.isSuccess) {
                this.IllusDnaService.onSetSource(resolveSource.result);
              }

              if (!response.result.isHaseResult || response.result.result === '[]') {
                this.runAndSaveAncestryBreakDown(findRegion);
              }
              this.onFetchAncestryRegionMaps();
            } else {
              AlertService.error(this, response.message)
            }
          });
      } else if (runType === 1) {
        this.runAndSaveAncestryBreakDown(findRegion);
        this.onFetchAncestryRegionMaps();
      }
    },
    runAndSaveAncestryBreakDown(findRegion) {
      this.$store.commit('setLoader', true);
      this.$store.commit('ancestrybreakdown/setAncestryPureResult', this.IllusDnaService.runABAlgorithm());
      this.$store.commit('setLoader', false);

      const results = this.$store.getters['ancestrybreakdown/getRegionPureResults'];
      const saveResultQuery = {
        calculatedResult: JSON.stringify(results),
        presetResionId: findRegion.id,
        popNumber: findRegion.id === 1 ? this.$store.state.ancestrybreakdown.numberOfGlobalnPop : 0,
        orderResultId: this.$store.state.orderResult.selectedResultId,
        presetAncientPeriodId: this.$store.state.operation.selectedAncientPeriod.id,
      };
      ApiService.postSilent('result/ancestrybreakdown', saveResultQuery, this);
    },
    onFetchAncestryRegionMaps() {
      const results = this.$store.getters['ancestrybreakdown/getRegionResults'];

      let resultMap = null;
      if (this.$store.state.ancestrybreakdown.isViewAllRegionMap) {
        resultMap = this.$store.getters['ancestrybreakdown/getRegionSourceDatas'];
        this.IllusDnaService.dataArrays.sourceCodeArray.forEach(item => {
          let sourceCode = item[0];
          if (sourceCode.includes(':')) {
            sourceCode = sourceCode.split(':')[0];
          }
          if (!resultMap.includes(sourceCode)) {
            resultMap.push(sourceCode);
          }
        });
      } else {
        resultMap = results.outPuts.map(item => item.resultsTable);
      }

      this.$store.dispatch('operation/fetchMapSourceDatas', { listOfSourceData: resultMap })
        .then(mapResponse => {
          if (mapResponse.statusCode === 200) {
            mapResponse.result.forEach(geoItem => {
              /* eslint-disable */
              let isFoundData = false;
              geoItem.title = 'Map Title Not Found';
              results.outPuts.forEach(resultItem => {
                if (resultItem.resultsTable === geoItem.sourceCode) {
                  resultItem.color = geoItem.color;
                  resultItem.pictureUrl = geoItem.pictureUrl;
                  resultItem.id = geoItem.id;
                  resultItem.sourceCode = geoItem.sourceCode;

                  geoItem.title = resultItem.resultsTable.replaceAll('*', ' ') + ' : ' + resultItem.currentResult.toFixed(1);
                  geoItem.name = resultItem.resultsTable.replaceAll('*', ' ');
                  geoItem.ancestryValue = resultItem.currentResult.toFixed(1);

                  isFoundData = true;
                }
              });

              if (isFoundData === false) {
                geoItem.title = geoItem.sourceCode.replaceAll('*', ' ') + ' : ' + 0;
                geoItem.name = geoItem.sourceCode.replaceAll('*', ' ');
                geoItem.ancestryValue = 0;
                geoItem.color = '#b8b8b8';
              }
            });
            this.$store.commit('operation/setAncestryGeoJson', mapResponse.result)
            this.$store.commit('ancestrybreakdown/setRegionFinalResults', results)
          } else {
            AlertService.error(this, mapResponse.message)
          }
        });
    },

    onChangeShowRegionMap() {
      this.$store.commit('operation/setAncestryGeoJson', []);
      this.onFetchAncestryRegionMaps();
    },
    openConfigDataModal() {
      this.modalConfigDataObject.showModal = true;
    },

    CalculateModernPopColor,
    AncestryFitStatus(value) {
      if(value > 0 && value <= 1) {
        return 'Very Good';
      }
      if(value > 1 && value <= 3) {
        return 'Good';
      }
      if(value > 3 && value <= 5) {
        return 'Moderate';
      }
      return 'Bad';
    },
  },
  computed: {
    ancestryGeoJson() {
      return this.$store.getters['operation/getAncestryGeoJson'];
    },
    regionResults() {
      return this.$store.getters['ancestrybreakdown/getRegionFinalResults'];
    },

    mapRegions() {
      const regions = this.$store.getters['operation/getRegions'];

      return regions.filter(x => x.presetRegionGrupId === this.$store.getters['ancestrybreakdown/getOrderSelectedRegionGrup']);
    },
    selectedRegionName() {
      const regionId = this.$store.getters['ancestrybreakdown/getOrderSelectedRegion'];

      const findRegion = this.mapRegions.find(x => x.id === regionId);

      return findRegion != null ? findRegion.title : 'Select Region';
    },
  },
  mounted() {
    this.$root.$refs.Periodical = this;
  },
}
</script>

<style lang="scss">
@import '../../../g25.scss';
</style>

<style lang="scss" scoped>
.periodical-card {
  margin-top: 1.5rem;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
}
.map-card {
  background: var(--bg-card-color);
  box-shadow: var(--lab-card-shadow);
  padding: 1.1rem;
  border-radius: 10px;

  .map-header {
    padding: 0 0 0.8rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .map-body {
    border-radius: 5px;
  }
}

.map-header-config {
  display: flex;
  align-items: center;

  .setting-container {
    padding: 0.3rem;
    border-radius: 5px;
    background-color: var(--bg-card-hover-color);
    cursor: pointer;
  }
  img {
    width: 20px;
    height: 20px;
  }
}

.map-header-fit-region {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// @media screen and (max-width: 1225px) {
//   .map-header-fit-region {
//     display: block;
//     .map-header-fit {
//       margin-bottom: 1rem;
//     }
//     .vertical-hr {
//       display: none;
//     }
//   }
// }


.lab-switch {
  margin-right: 1rem;
}
.lab-switch input:checked + .slider {
  background-color: var(--lab-turquoise-sea);
}

.switch-map-type {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  
  .switch-map-type-label {
    font-size: 1rem !important;
    color: var(--lab-carbon-fiber)
  }
}

.dark-layout   .switch-map-type-label {
  color: var(--lab-white)
}
</style>
