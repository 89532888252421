<template>
  <div  class="main-hgfarmer">
    <b-row>
      <b-col cols="12" xl="8" md="8">
        <div 
          v-for="itemAncestry in farmerHgsResults.outPuts"
          :key="itemAncestry.resultsTable">
          <div class="hgfarmer-item" @click="onOpenDetail(itemAncestry)">
            <div class="hgfarmer-title">
                {{ itemAncestry.resultsTable.replaceAll('*', ' ') }}
            </div>
            <div class="d-flex align-items-center">
              <span class="hgfarmer-pop mr-75 " :style="{ color: itemAncestry.color}"><b>{{ itemAncestry.currentResult.toFixed(1) }}%</b></span>
              <feather-icon
                :icon="'ChevronDownIcon'"
                size="16"
              />
            </div>
          </div>
          <div class="hgfarmer-item-detail" v-if="detailItem !== null && prevItemCode === itemAncestry.resultsTable">
            <div class="d-flex justify-content-center" v-if="detailItem.pictureUrl">
              <b-img
                class="mb-1"
                :src="detailItem.pictureUrl"
                height="180"
              />
            </div>
            <h5 class="mt-1"><b>{{ detailItem.title }}</b>:</h5>
            <b-card-text class="text-font-size" v-html="formattedTextHTML(detailItem.description)"></b-card-text>

            <h5 class="mt-1"><b>{{ $t('Reference Samples') }}</b>:</h5>
            <b-card-text class="text-font-size" v-html="formattedTextHTML(detailItem.referenceSamples)"></b-card-text>

            <h5 class="mt-1"><b>{{ $t('Research Link(s)') }}</b>:</h5>
            <b-card-text v-html="detailItem.researchLinks"></b-card-text>
          </div>
        </div>
      </b-col>

      <b-col cols="12" xl="4" md="4">
        <div class="pop-view-card list-card">
          <div class="dashboard-card pop-view-header">
            <div class="title-header">
              <h4>Summary</h4>
            </div>
          </div>

          <div class="pop-view-body" v-if="farmerHgsResults != null">
            <div class="pop-list-item"
              v-for="itemAncestry in farmerHgsResults.outPuts"
              :key="itemAncestry.resultsTable">

              <div class="pop-left">
                {{ itemAncestry.resultsTable.replaceAll('*', ' ') }}
                <div class="pop-progress" :style="{ backgroundColor: ddAlpha(itemAncestry.color, 0.1) , width: '100%' }">
                  <div class="pop-progress-fill" :style="{ backgroundColor: itemAncestry.color, width: itemAncestry.currentResult.toFixed(1) + '%' }"></div>
                </div>
              </div>
              <div class="pop-value" :style="{ color: itemAncestry.color}" >
                {{itemAncestry.currentResult.toFixed(1)}}%
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApiService from '@/common/api.service'

export default {
  components: {
  },
  directives: {
  },
  data() {
    return {
      /* eslint-disable */
      detailItem: null,
      prevItemCode: null,
      chartOption: null,
      chartSeries: null,
    }
  },
  methods: {
    // Runs
    onChangeFarmerAndHGs() {
      const selectedResultId = this.$store.state.orderResult.selectedResultId;
      const selectedResult = this.$store.state.farmerhgs.farmerAndHGsResults.find(x => x.selectedResultId === selectedResultId);
      
      if (selectedResult) {
        this.OnRunFarmerAndHGs();
      } else {
        const query = {
          sourceDataType: 6,
          selectedResultId: selectedResultId,
        }
        this.$store.dispatch('farmerhgs/fetchFarmerAndHGsSourceDatas', query)
          .then(response => {
            if (response.statusCode === 200) {
              this.$store.commit('farmerhgs/setFarmerAndHGsSourceData', response.result);
              
              if (!response.result.isHaseResult) {
                this.$store.commit('farmerhgs/runSingleFarmerHGs');
                
                const results = this.$store.getters['farmerhgs/getFarmerAndHGsResult'];
                const saveResultQuery = {
                  calculatedResult: JSON.stringify(results),
                  orderResultId: selectedResultId,
                };
                ApiService.postSilent('result/farmerhgs', saveResultQuery, this);
              }
              
              this.OnRunFarmerAndHGs();
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },
    OnRunFarmerAndHGs() {
      const results = this.$store.getters['farmerhgs/getFarmerAndHGsResult'];
      const resultFarmerHGs = results.outPuts.map(item => item.resultsTable);
      this.$store.dispatch('operation/fetchFarmerAndHGsDatas', { listOfSourceData: resultFarmerHGs })
        .then(response => {
          if (response.statusCode === 200) {
            results.outPuts.map(item => {
              const findFarmerHGsItem = response.result.find(x => x.sourceCode === item.resultsTable);
              if (findFarmerHGsItem) {
                item.color = findFarmerHGsItem.color;
                item.pictureUrl = findFarmerHGsItem.pictureUrl;
                item.id = findFarmerHGsItem.id;
                item.sourceCode = findFarmerHGsItem.sourceCode;
              }
              item.isOpenDetail = false;
              return item;
            });
            
            this.$store.commit('farmerhgs/setFarmerAndHGsFinalResults', results)
          }
        });
    },

    // Modals
    onOpenDetail(item) {
      if (item.resultsTable === this.prevItemCode) {
        this.detailItem = null;
        this.prevItemCode = null;
      } else {
        this.$store.dispatch('operation/fetchFarmerAndHGsDetailData', { sourceCode: item.resultsTable })
          .then(response => {
            if (response.statusCode === 200) {
              this.prevItemCode = item.resultsTable;
              this.detailItem = response.result;
            }
          });
      }
    },

    // Options
    textColor(color) {
      return this.$store.state.appConfig.layout.skin === 'light' ? '#22212B' : color;
    },

    ddAlpha(color, opacity) {
      let _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
    
    formattedTextHTML(description) {
      return description ? description.replace(/\n/g, '<br>') : '';
    },
  },
  computed: {
    farmerHgsResults() {
      return this.$store.getters['farmerhgs/getFarmerAndHGsFinalResult'];
    },
  },
  mounted() {
    this.$root.$refs.FarmerAndHGs = this;
  },
}
</script>

<style lang="scss">
@import '../../../g25.scss';
</style>

<style lang="scss" scoped>
.main-hgfarmer {
  margin: 0 auto;
  max-width: 1200px !important;
}
.hgfarmer-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--bg-card-color);
  box-shadow: var(--lab-card-shadow);
  transition: var(--lab-trans-03);
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 10px;
  cursor: pointer;

  .hgfarmer-title {
    font-weight: bold;
    font-size: 1rem;
    color: var(--lab-carbon-fiber);
  }
  .hgfarmer-pop {
    font-weight: bold;
    font-size: 1rem;
    color: var(--lab-carbon-fiber);
  }
}

.dark-layout .hgfarmer-item {
  .hgfarmer-title, .hgfarmer-pop {
    color: var(--lab-turquoise-sea);
  }
}

.hgfarmer-item:hover {
  transform: rotate(0deg) scale(1.01, 1.01);
}

.hgfarmer-item-detail {
  background: var(--bg-card-color);
  box-shadow: var(--lab-card-shadow);
  transition: var(--lab-trans-05);
  padding: 2rem;
  margin-bottom: 15px;
  border-radius: 10px;
  transition: var(--lab-trans-05);
}

.title-header::before { 
  background-color: var(--lab-turquoise-sea);
}
.dark-layout .title-header h4 {
  color: var(--lab-turquoise-sea);
}

.dark-layout .hgfarmer-item-detail h5 {
  color: var(--lab-turquoise-sea);
}

.dark-layout .pop-view-card h6, 
.dark-layout .pop-view-card .pop-list-item .pop-left,
.dark-layout .pop-view-card .pop-list-item .pop-value  {
  color: var(--lab-light-house);
}

.text-font-size {
  font-size: 0.85rem !important;
  padding: 0.4rem 1rem 1rem 1rem !important;
}
</style>
