<template>
  <div class="main-g25">
    <unsupervised-ancient-help />
    <b-row class="mt-2">
      <b-col cols="12" xl="12" md="12">
        <div class="d-flex align-items-center justify-content-between hidden-desktop mb-2">
          <button class="lab-btn lab-btn-outline-seccondary ml-1"
            v-if="mixedModeObject.mixedModeVersion === $store.state.ancientMixedmode.selectedPeriod.sourceCodeVersion">
            Latest Version
          </button>
          <button class="lab-btn lab-btn-outline-marigold ml-1" @click="onUpdateResult()"
            v-else>
            Update Available
          </button>
          <mixed-mode-ancient-periods class="ml-1" :Initialize="Initialize"/>
        </div>

        <div class="number-card">
          <div class="number-card-header">
            <h4 class="unsupervised-text">Number Of Models</h4>

            <div class="d-flex align-items-center justify-content-between hidden-mobile">
              <button class="lab-btn lab-btn-outline-seccondary ml-1"
                v-if="mixedModeObject.mixedModeVersion === $store.state.ancientMixedmode.selectedPeriod.sourceCodeVersion">
                Latest Version
              </button>
              <button class="lab-btn lab-btn-outline-marigold ml-1" @click="onUpdateResult()"
                v-else>
                Update Available
              </button>
              <mixed-mode-ancient-periods class="ml-1" :Initialize="Initialize"/>
            </div>

          </div>
          <div class="number-card-body">
            <div class="d-flex align-items-center justify-content-start">
              <div class="slider-container">
                <vue-slider
                  v-model="$store.state.ancientMixedmode.ancientMixedModePopNumber"
                  :adsorb="true"
                  :data="sliderData"
                  :marks="true"
                  direction="ltr"
                  class="mixed-mode-slider"
                />
              </div>
              <div class="lab-timeline-switch hidden-mobile">
                <div class="lab-timeline-switch hidden-mobile">
                  <div class="timeline-item" @click="switchLeft()">
                    <b-img :src="'https://api.illustrativedna.com/assets/icons/time_line_left.svg'" />
                  </div>
                  <div class="timeline-item last"  @click="switchRight()">
                    <b-img :src="'https://api.illustrativedna.com/assets/icons/time_line_right.svg'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="!$store.state.ancientMixedmode.selectedPeriod.sourceDataId">
      <b-col cols="12" xl="12" md="12" class="mt-2">
        <b-alert
          variant="default"
          class="text-center"
          show
        >
          <div class="alert-body lab-unsupervised-badge">
            <h5>We are working on Period 4, it will be ready soon.</h5>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="mixedModeObject.orderResultStatus === 3">
      <b-col cols="12" xl="12" md="12" class="mt-2">
        <b-alert
          variant="info"
          class="text-center"
          show 
        >
          <div class="alert-body lab-unsupervised-badge">
            <span><strong>These results are under process.</strong> Please check back in few hours.</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="$store.state.ancientMixedmode.selectedPeriod.sourceDataId && ancientTwoWay.length && ancientThreeWay.length">
      <b-col cols="12" xl="6" md="6" class="mt-2">
        <div class="mixedmode-container">
          <div class="mixedmode-container-top">
            <div class="mixedmode-container-header">
              <h4 class="unsupervised-text">Two Way</h4>
            </div> 
          </div>
          <div class="mixedmode-container-body  cs-scroll-style">
            <div class="mixedmode" v-for="twoWay in ancientTwoWay" :key="twoWay.id">
              <div class="mixedmode-header">
                <div class="text-number unsupervised-text">
                  Model#{{ twoWay.id + 1 }}
                </div>
                <div class="text-fit">
                  Fit: 
                  <span :style="{ color: CalculateModernPopColor(twoWay.fit) }">{{ twoWay.fit }}</span>
                </div>
              </div>
              <div class="mixedmode-body">
                  <ancient-mixed-mode-card :wayObject="twoWay"/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" xl="6" md="6" class="mt-2">
        <div class="mixedmode-container">
          <div class="mixedmode-container-top">
            <div class="mixedmode-container-header">
              <h4 class="unsupervised-text">Three Way</h4>
            </div>
          </div>

          <div class="mixedmode-container-body cs-scroll-style">
            <div class="mixedmode" v-for="threeWay in ancientThreeWay" :key="threeWay.id">
              <div class="mixedmode-header">
                <div class="text-number unsupervised-text">
                  Model#{{ threeWay.id + 1 }}
                </div>
                <div class="text-fit">
                  Fit: 
                  <span :style="{ color: CalculateModernPopColor(threeWay.fit) }">{{ threeWay.fit }}</span>
                </div>
              </div>
              <div class="mixedmode-body">
                  <ancient-mixed-mode-card :wayObject="threeWay"/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import { CalculateModernPopColor } from '@/common/custom-service/dna-source/utils'
import VueSlider from 'vue-slider-component'
import mixedModeHelper from './mixedModeHelper'

import MixedModeAncientPeriods from './MixedModeAncientPeriods.vue'
import AncientMixedModeCard from './cards/AncientMixedModeCard.vue'
import UnsupervisedAncientHelp from './helps/UnsupervisedAncientHelp.vue'

export default {
  components: {
    VueSlider,
    MixedModeAncientPeriods,
    AncientMixedModeCard,
    UnsupervisedAncientHelp,
  },
  directives: {
  },
  props: {
  },
  data() {
    return {
      sliderData: ['10', '20', '30', '40', '50'],
    };
  },
  methods: {
    Initialize() {
      this.$store.commit('ancientMixedmode/setMixedModeResult', {});
      this.$store.commit('ancientMixedmode/setSelectedAncientMixedModeResult', {});
      this.$store.dispatch('ancientMixedmode/fetchAncientMixedModePeriods', {})
          .then(() => {
            this.onRun();
          });
    },
    onRun() {
      const findSelected = this.$store.getters['ancientMixedmode/getSelectedMixedModeResult'];
      
      if (findSelected.id) {
        this.onSetMixedModeResult();
      } else {
        this.fetchMixedModeResults();
      }
    },

    // Runs
    onSetMixedModeResult() {
      const findSelected = this.$store.getters['ancientMixedmode/getSelectedMixedModeResult'];
      if (findSelected) {
        const mixedModeResult = mixedModeHelper.MixedModeRunAll(findSelected);
        if (mixedModeResult.threeWayResult && mixedModeResult.twoWayResult) {
          const ancientMixedModeResult = { threeWayResult: mixedModeResult.threeWayResult, twoWayResult: mixedModeResult.twoWayResult };
          const queryParams = mixedModeHelper.ResolveAncientCoordinates(ancientMixedModeResult);
          
          this.$store.dispatch('operation/fetchAncientProxImageDatas', { listOfSourceData: queryParams })
            .then(response => {
              if (response.statusCode === 200) {
                ancientMixedModeResult.threeWayResult = ancientMixedModeResult.threeWayResult.map(item => {
                  let findDistItem = response.result.find(x => x.sourceCode === item.firstPop[1]);
                  if (findDistItem) {
                    item.pictureUrl1 = findDistItem.pictureUrl;
                    item.id1 = findDistItem.id;
                  }

                  findDistItem = response.result.find(x => x.sourceCode === item.secondPop[1]);
                  if (findDistItem) {
                    item.pictureUrl2 = findDistItem.pictureUrl;
                    item.id2 = findDistItem.id;
                  }

                  findDistItem = response.result.find(x => x.sourceCode === item.thirdPop[1]);
                  if (findDistItem) {
                    item.pictureUrl3 = findDistItem.pictureUrl;
                    item.id3 = findDistItem.id;
                  }

                  return item;
                });

                ancientMixedModeResult.twoWayResult = ancientMixedModeResult.twoWayResult.map(item => {
                  let findDistItem = response.result.find(x => x.sourceCode === item.firstPop[1]);
                  if (findDistItem) {
                    item.pictureUrl1 = findDistItem.pictureUrl;
                    item.id1 = findDistItem.id;
                  }

                  findDistItem = response.result.find(x => x.sourceCode === item.secondPop[1]);
                  if (findDistItem) {
                    item.pictureUrl2 = findDistItem.pictureUrl;
                    item.id2 = findDistItem.id;
                  }

                  return item;
                });
                
                this.$store.commit('ancientMixedmode/setSelectedAncientMixedModeResult', ancientMixedModeResult)
              }
            });
        }
      }
    },
    fetchMixedModeResults() {
      const resultId = this.$store.state.orderResult.selectedResultId;
      const periodId = this.$store.state.ancientMixedmode.selectedPeriod.id;
      if (resultId === null || periodId === 0) {
        return;
      }
      this.$store.dispatch('ancientMixedmode/fetchOrderMixedModeResults', { orderResultId: resultId, mixedModePeriodId: periodId })
        .then(response => {
          if (response.statusCode === 200) {
            this.$store.commit('ancientMixedmode/setMixedModeResult', response.result);
            this.onSetMixedModeResult();
          }
        });
    },

    // Options
    CalculateModernPopColor,
    onUpdateResult() {
      this.$store.dispatch('ancientMixedmode/updateOrderResultMixedModeResults', { id: this.mixedModeObject.orderResultId })
        .then(response => {
          if (response.statusCode === 200) {
            this.mixedModeObject.orderResultStatus = 3;
            AlertService.success(this, response.message)
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    onSetPeriod(periodId) {
      this.$store.commit('ancientMixedmode/setMixedModerPeriodId', periodId);
      this.$store.commit('ancientMixedmode/setMixedModeResult', {});
      this.Initialize();
    },

    switchLeft() {
      /* eslint-disable */
      if (this.ancientPopNumber != '10') {
        this.$store.state.ancientMixedmode.ancientMixedModePopNumber = (parseInt(this.ancientPopNumber) - 10).toString();
      }
    },
    switchRight() {
      /* eslint-disable */
      if (this.ancientPopNumber != '50') {
        this.$store.state.ancientMixedmode.ancientMixedModePopNumber = (parseInt(this.ancientPopNumber) + 10).toString();
      }
    },
  },
  computed: {
    mixedModeObject() {
      const result = this.$store.getters['ancientMixedmode/getSelectedMixedModeResult'];
      if (result) {
        return result;
      }
      return {};
    },
    ancientThreeWay() {
      /* eslint-disable */
      const selectedMixedMode = this.$store.getters['ancientMixedmode/getSelectedAncientMixedModeResult'];
      const ancientMixedModePopNumber = this.$store.getters['ancientMixedmode/getAncientMixedModePopNumber'];
      
      if (selectedMixedMode != null) {
        if (selectedMixedMode.threeWayResult) { 
          return selectedMixedMode.threeWayResult.slice(0, ancientMixedModePopNumber);
        }
      }

      return [];
    },
    ancientTwoWay() {
      /* eslint-disable */
      const selectedMixedMode = this.$store.getters['ancientMixedmode/getSelectedAncientMixedModeResult'];
      const ancientMixedModePopNumber = this.$store.getters['ancientMixedmode/getAncientMixedModePopNumber'];
      
      if (selectedMixedMode != null) {
        if (selectedMixedMode.twoWayResult) {
          return selectedMixedMode.twoWayResult.slice(0, ancientMixedModePopNumber);
        }
      }

      return [];
    },
    ancientPopNumber() {
      return this.$store.getters['ancientMixedmode/getAncientMixedModePopNumber'];
    },
  },
  mounted() {
    this.$root.$refs.MixedModeAncient = this;
  },
};
</script>

<style lang="scss">
@import '../../g25.scss';
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
.mixed-mode-slider .vue-slider-dot-handle {
  border: 2px solid var(--lab-g25-unsupervised-color) !important;
}

.mixed-mode-slider .vue-slider-dot-tooltip-inner {
  background-color: var(--lab-g25-unsupervised-color) !important;
  border-color: var(--lab-g25-unsupervised-color) !important;
}
.mixed-mode-slider .vue-slider-dot-handle {
  border: 2px solid var(--lab-g25-unsupervised-color) !important;

  &-focus {
    box-shadow: 0px 0px 1px 2px #f5e3c4 !important;
  }
}

.mixed-mode-slider .vue-slider-process {
  background-color: var(--lab-g25-unsupervised-color) !important;
}

.mixed-mode-slider .vue-slider-rail {
  background-color:  #f5e3c4 !important;
}

.dark-layout .mixed-mode-slider .vue-slider-rail {
  background-color: var(--lab-black) !important;
}

.mixed-mode-slider .vue-slider-dot-tooltip-inner {
  background-color: var(--lab-g25-unsupervised-color) !important;
  border-color: var(--lab-g25-unsupervised-color) !important;
}
</style>

<style lang="scss" scoped>
.slider-container {
  width: 90%;
  padding: 0 1rem;
}

@media screen and (max-width: 769px) {
  .slider-container {
    width: 100%;
  }
}

.lab-timeline-switch {
  display: flex;
  align-items: center;
  justify-content: end;
  .timeline-item {
    background-color: var(--lab-g25-unsupervised-color) !important;
  }
}
</style>
